<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"><svg v-svg symbol="x" size="0 0 17 17" /></span>
    </button>
    <div class="modal-body">
      <div class="title-h2 pb-2">
        {{ $t(`platform.${platform.site ? "code" : "link"}_for_a_platform`) }}:
        {{ platform.id }}
      </div>
      <PlatformValue :platform="platform" />
    </div>
  </div>
</template>
<script>
import "@/assets/css/_tubs.scss";
import "@/assets/css/_code.scss";
import "@/assets/css/_modal.scss";
import PlatformValue from "@/components/PlatformValue";

export default {
  name: "PlatformModal",
  components: {
    PlatformValue,
  },
  props: {
    platform: {
      type: Object,
      default: null,
    },
  },
};
</script>