<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("platforms.title") }}</h1>
      </div>
      <div class="col-12 col-xl-auto flex-grow-1 max-770">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <div class="form-item pb-2">
              <div class="form-item__field">
                <div class="search-field">
                  <input
                    v-model="search"
                    type="search"
                    @keydown.enter="getPlatforms"
                    @blur="getPlatforms"
                    :placeholder="$t('platforms.enter_site_name')"
                  />
                  <button @click="getPlatforms" class="icon-search">
                    <svg v-svg symbol="search" size="0 0 15 16" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="form-item pb-2">
              <div class="form-item__field">
                <multiselect
                  label="title"
                  v-model="trash"
                  :options="filterPeriod"
                  :searchable="false"
                  placeholder=""
                  :show-labels="false"
                  :allowEmpty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span>{{ formatLabel(option.title) }}</span>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <span>{{ formatLabel(option.title) }}</span>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="form-item pb-2">
              <div class="form-item__field">
                <router-link :to="'/create-platform'" class="btn _w100"
                  >+ {{ $t("platforms.create_new") }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sep _h20"></div>
    <div class="c-table mt-5 mt-md-0">
      <tableAnimation />
      <div class="inner">
        <vue-good-table
          styleClass="table  _no-wrap"
          :columns="columns"
          :rows="rows"
        >
          <template slot="table-column" slot-scope="props">
            <span @click="toSort(props.column.field)" class="cursor-pointer">{{
              formatLabel(props.column.label)
            }}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'name'">
              <span v-if="props.row.site" class="label-site">{{
                $t("platform.my_site")
              }}</span>
              {{ props.row.name }}
            </template>
            <template v-else-if="props.column.field === 'profit'">
              <span class="font-weight-bold"
                >${{ numberFormat(props.row.profit) }}</span
              >
            </template>
            <template v-else-if="props.column.field === 'landings'">
              <div class="land-links">
                <ul v-if="props.row.lands && props.row.lands.length > 0">
                  <li v-for="(item, i) in props.row.lands" :key="i">
                    <button
                      @click="openLandModal(item)"
                      class="land-links__item"
                    >
                      {{ item.name }}
                    </button>
                  </li>
                </ul>
                <span v-else-if="props.row.url">
                  <a :href="props.row.url" target="__blank">{{
                    props.row.url
                  }}</a>
                </span>
              </div>
            </template>
            <template v-else-if="props.column.field === 'reference'">
              <div class="row">
                <button class="btn _text" @click="openLinkModal(props.row)">
                  <span class="icon-link">
                    <svg v-svg symbol="link" size="0 0 17 17" />
                  </span>
                  {{
                    $t(
                      `platforms.table.${
                        props.row.site ? "get_code" : "get_link"
                      }`
                    )
                  }}
                </button>
              </div>
            </template>
            <template v-else-if="props.column.field === 'control'">
              <ul class="controls">
                <li>
                  <router-link
                    :to="`/edit-platform/${props.row.id}`"
                    class="controls__btn"
                  >
                    <svg v-svg symbol="edit" size="0 0 14 15" role="info" />
                  </router-link>
                </li>
                <li>
                  <button
                    @click="clonePlatform(props.row.id)"
                    class="controls__btn"
                  >
                    <svg v-svg symbol="copy" size="0 0 13 15" role="info" />
                  </button>
                </li>
                <li>
                  <button
                    @click="trashPlatform(props.row.id, props.row.trash)"
                    class="controls__btn"
                  >
                    <svg
                      v-if="!trash.value"
                      v-svg
                      symbol="delete"
                      size="0 0 15 15"
                      role="info"
                    />
                    <svg
                      v-else
                      v-svg
                      symbol="undo"
                      size="0 0 15 15"
                      role="info"
                    />
                  </button>
                </li>
              </ul>
            </template>
          </template>
          <div slot="emptystate">
            {{ $t("any_results") }}
          </div>
        </vue-good-table>
      </div>
    </div>
    <div class="sep"></div>
    <div class="c-pagination">
      <ul class="pagination" v-if="rows && rows.length && rows.length > 0">
        <li class="page-item">
          <button
            @click.prevent="page--"
            :class="{ disabled: page <= 1 }"
            :disabled="page <= 1"
            class="page-link icon-prev prev"
            data-ci-pagination-page="2"
            rel="prev"
          >
            <svg v-svg symbol="prev" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">
          <button
            @click.prevent="page++"
            :class="{ disabled: page * limit >= total }"
            :disabled="page * limit >= total"
            class="page-link icon-next next"
            data-ci-pagination-page="2"
            rel="next"
          >
            <svg v-svg symbol="next" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">
          {{ page === 1 ? page : limit * (page - 1) + 1 }} -
          {{ limit * (page - 1) + rows.length }} из {{ total }}
        </li>
        <li>
          <multiselect
            v-model="limit"
            :options="optionsPagination"
            :searchable="false"
            placeholder=""
            :show-labels="false"
          />
        </li>
      </ul>
    </div>
    <modal
      @closed="clearSelected"
      :width="780"
      name="massModal"
      height="auto"
      :scrollable="true"
      classes="creative-modal"
      :shiftY="0"
    >
      <PlatformModal :platform="currentPlatform" @closeModal="closeModal" />
    </modal>
    <modal
      @closed="clearLandingModal"
      :width="780"
      name="landModal"
      height="auto"
      :scrollable="true"
      classes="creative-modal"
      :shiftY="0"
    >
      <LandingModal :landing="currentLanding" @closeModal="closeLandingModal" />
    </modal>
  </div>
</template>

<script>
import "@/assets/css/_pagination.scss";
import PlatformModal from "@/components/PlatformModal";
import LandingModal from "@/components/LandingModal";
import TableAnimation from "@/components/TableAnimation";
import subaccsService from "@/api/subaccsService";
import LandingMixin from "@/mixins/landModal";

export default {
  name: "Platforms",
  beforeMount() {
    document.title = `Revopush | ${this.$t("platforms.title")}`;
  },
  beforeUpdate() {
    document.title = `Revopush | ${this.$t("platforms.title")}`;
  },
  components: { TableAnimation, PlatformModal, LandingModal },
  mixins: [LandingMixin],
  data() {
    return {
      trash: { title: "platform.active", value: 0 },
      search: null,
      offset: null,
      limit: 10,
      order: null,
      sort: null,

      page: 1,

      platforms: [],
      openLinkModel: false,
      filterPeriod: [
        { title: "platform.active", value: 0 },
        { title: "platform.deleted", value: 1 },
      ],
      currentPlatform: null,
      columns: [
        {
          label: "platforms.table.id",
          field: "id",
          thClass: `sortable spec-sort`,
          sortable: true,
        },
        {
          label: "platforms.table.name",
          field: "name",
          thClass: `sortable spec-sort`,
          sortable: true,
        },
        {
          label: "platforms.table.profit",
          field: "profit",
          thClass: `sortable spec-sort`,
          sortable: true,
        },
        {
          label: "platforms.table.payment",
          field: "payment_model",
          thClass: `sortable spec-sort`,
          sortable: true,
        },
        {
          label: "platforms.table.landing_pages",
          field: "landings",
          sortable: false,
        },
        {
          label: "platforms.table.reference",
          field: "reference",
          width: "200px",
          sortable: false,
        },
        {
          label: "platforms.table.control",
          field: "control",
          width: "170px",
          sortable: false,
        },
      ],
      rows: [],
      total: null,
      optionsPagination: [10, 25, 50, 100],
    };
  },
  mounted() {
    this.getPlatforms();
  },
  watch: {
    $route: function () {
      const params = this.$route.query;
      this.offset = params.offset || 0;
      if (this.offset === 0) {
        this.page = 1;
      } else {
        this.page = this.offset / this.limit + 1;
      }
    },
    sort: function () {
      this.getPlatforms();
      this.checkTableSpan();
    },
    order: function () {
      this.getPlatforms();
      this.checkTableSpan();
    },
    trash: function () {
      this.getPlatforms();
    },
    limit: function () {
      this.page = 1;
      this.getPlatforms();
    },
    offset: function () {
      this.getPlatforms();
    },
    page: function () {
      if (this.page === 1 || this.page < 0) {
        this.offset = null;
        this.$router
          .push({
            path: "/platforms",
          })
          .catch(() => {});
      } else {
        this.offset = this.limit * (this.page - 1);
        this.$router
          .push({
            path: "/platforms",
            query: { offset: this.offset },
          })
          .catch(() => {});
      }
    },
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    trashPlatform(id, trash) {
      subaccsService.trashSubAcc({ id, trash }).then(() => {
        this.getPlatforms();
      });
    },
    checkTableSpan() {
      const thElements = document.querySelectorAll("th.sortable"); // all sortable table columns
      thElements.forEach((thElement) => {
        const spanElement = thElement.querySelector("span"); // span of an current iteration th
        if (
          spanElement &&
          spanElement.innerText ===
            this.$t(this.columns.find((el) => el.field === this.sort).label)
        ) {
          // check if an span text === current sort value text
          thElement.classList.remove(
            // remove oposite class
            this.order && this.order === "desc" ? "sorting-asc" : "sorting-desc"
          );
          thElement.classList.add(
            // add class to the current sort th
            this.order && this.order === "desc" ? "sorting-desc" : "sorting-asc"
          );
          return false;
        } else {
          // if no remove all classes
          thElement.classList.remove("sorting-desc", "sorting-asc");
        }
      });
    },
    toSort(val) {
      if (!(val === "landings" || val === "reference" || val === "control")) {
        this.order =
          this.sort === val ? (this.order === "desc" ? "asc" : "desc") : "asc";
        this.sort = val;
      }
    },
    getPlatforms() {
      let params = this.$route.query;
      const payload = {
        trash: this.trash.value,
        search: this.search === "" ? null : this.search,
        offset: params.offset ? params.offset : this.offset,
        limit: this.limit,
        order: this.order,
        sort: this.sort,
      };
      subaccsService.getSubAccs(payload).then((res) => {
        if (res.status === 200) {
          this.rows = res.data.subaccounts;
          this.total = res.data.total;
        }
      });
    },
    clonePlatform(id) {
      subaccsService.cloneSubAcc(id).then(() => {
        this.getPlatforms();
      });
    },
    closeModal() {
      this.$modal.hide("massModal");
      this.clearSelected();
      this.currentPlatform = null;
    },
    openLinkModal(platform) {
      this.currentPlatform = platform;
      this.$modal.show("massModal");
    },
    clearSelected() {
      this.openLinkModel = null;
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-search {
  pointer-events: all;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.content.content_in {
  padding-bottom: 150px;

  &::v-deep {
    table thead th.sortable {
      cursor: pointer;
    }
  }
}

.domain {
  background: linear-gradient(180deg, #44978d 0%, #2a686c 100%);
  border-radius: 15px;
  padding: 25px 35px 10px;
  color: #d9e3e2;
  @media screen and (max-width: 767px) {
    padding: 20px 20px 5px;
  }
  font-size: 14px;
  .btn {
    height: 45px;
  }
  h2 {
    color: #fff;
  }
}

.label-site {
  font-weight: 500;
  font-size: 12px;
  background: #ecd47e;
  border-radius: 3px;
  padding: 4px 5px;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
}

.icon-link {
  svg {
    fill: #317475;
  }
}

.btn._text:hover,
.page-link:hover {
  svg {
    fill: #fff;
  }
}

.page-link.disabled {
  svg {
    fill: #000109;
  }
}

.land-links {
  overflow: hidden;
  max-width: 520px;
  position: relative;
  @media screen and (max-width: 1600px) {
    max-width: 320px;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(
      267.66deg,
      #f7f7f9 49.35%,
      rgba(247, 247, 249, 0.33) 63.71%
    );
    pointer-events: none;
    transition: 0.3s;
    z-index: 20;
  }
  &:before {
    background: linear-gradient(
      267.66deg,
      #fff 49.35%,
      rgba(255, 255, 255, 0.33) 63.71%
    );
    z-index: 19;
  }
  ul {
    display: flex;
    margin: 0 -5px;
    overflow: auto;
    padding: 3px 20px 3px 0;
  }
  li {
    padding: 0 5px;
    .land-links__item {
      display: flex;
      align-items: center;
      height: 28px;
      padding: 0 13px 0 8px;
      position: relative;
      background: #ffffff;
      border: 1px solid #e4e4ea;
      border-radius: 5px;
      font-size: 12px;
      &:before {
        content: "";
        display: block;
        background: url("~@/assets/images/icons/open.svg") center no-repeat;
        background-size: contain;
        width: 7px;
        height: 7px;
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 7px;
      }
      @media (hover: hover) {
        &:hover {
          border-color: #000109;
          color: #000109;
        }
      }
    }
  }
}
</style>



