<template>
  <div class="tubs-content__item">
    <div class="code large">
      <div class="hidden">
        <textarea readonly id="htttpsCopyLink" v-model="link" />
        <textarea readonly id="htttpsCopyCode" v-model="platformCode" />
      </div>
      <button type="button" class="btn _has-icon lower-link" @click="copy">
        <span class="icon-copy">
          
          <svg v-svg symbol="copy" size="0 0 13 15" role="info" /> </span
        >{{ $t("copy") }}
      </button>
      <code v-html="platform.site ? code : link" class="code__content"> </code>
    </div>
  </div>
</template>
<script>
export default {
  name: "Code",
  props: {
    platform: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      code: `&lt;script type="text/javascript"<br />
        src="https://domain.com/process.js?id=${this.platform.id}&sub1=sub1"<br />
        async&gt;&lt;/script&gt;`,
      link: `https://news-lands.cc/?id=${this.platform.id}&p1=sub1&p2=sub2&p3=sub3&p4=sub4`,
    };
  },
  computed: {
    platformCode() {
      return (
        '<script type="text/javascript"' +
        `src="https://domain.com/process.js?id=${this.platform.id}&sub1=sub1"` +
        `async>` +
        `<` +
        `/` +
        `script>`
      );
    },
  },
  methods: {
    copy() {
      if (this.platform.site) {
        this.$helper.copyCode("htttpsCopyCode");
      } else {
        this.$helper.copyCode("htttpsCopyLink");
      }
    },
  },
};
</script>